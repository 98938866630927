@import "variables";
@import "mixins";
@import '../../../utils/variables.scss';

.br-theme-bars-movie {

  .br-widget {
    height: 50px;
    white-space: nowrap;

    a {
      display: block;
      width: 60px;
      height: 8px;
      float: left;
      background-color: lighten($primary-color, 25%);
      margin: 1px;

      &.br-active,
      &.br-selected {
        background-color: var(--theme-deafult);
      }
    }

    .br-current-rating {
      clear: both;
      width: 240px;
      text-align: center;
      font-weight: 600;
      display: block;
      padding: .5em 0;
      color: var(--theme-deafult);
      font-weight: 400;
    }
  }

  .br-readonly {
    a {
      cursor: default;

      &.br-active,
      &.br-selected {
        background-color: lighten($primary-color, 10%);
      }
    }

    .br-current-rating {
      color: lighten($primary-color, 10%);
    }
  }

}

@media print {
  .br-theme-bars-movie {

    .br-widget {
      a {
        border: 1px solid lighten(black, 70%);
        background: white;
        @include box-sizing(border-box);

        &.br-active,
        &.br-selected {
          border: 1px solid black;
          background: white;
        }
      }

      .br-current-rating {
        color: black;
      }
    }

  }
}