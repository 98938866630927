@import "variables";
@import "mixins";
@import '../../../utils/variables.scss';

.br-theme-bars-pill {

  .br-widget {
    white-space: nowrap;

    a {
      padding: 7px 15px;
      background-color: lighten($primary-color, 25%);
      color: var(--theme-deafult);
      text-decoration: none;
      font-size: 13px;
      line-height: 3;
      text-align: center;
      font-weight: 400;

      &:first-child {
        -webkit-border-top-left-radius: 999px;
        -webkit-border-bottom-left-radius: 999px;
        -moz-border-radius-topleft: 999px;
        -moz-border-radius-bottomleft: 999px;
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
      }

      &:last-child {
        -webkit-border-top-right-radius: 999px;
        -webkit-border-bottom-right-radius: 999px;
        -moz-border-radius-topright: 999px;
        -moz-border-radius-bottomright: 999px;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
      }

      &.br-active,
      &.br-selected {
        background-color: var(--theme-deafult);
        color: white;
      }
    }
  }

  .br-readonly {
    a {
      cursor: default;

      &.br-active,
      &.br-selected {
        background-color: lighten($primary-color, 10%);
      }
    }
  }

}

@media print {
  .br-theme-bars-pill {

    .br-widget {
      a {
        border: 1px solid lighten(black, 70%);
        border-left: none;
        background: white;
        @include box-sizing(border-box);

        &.br-active,
        &.br-selected {
          border: 1px solid black;
          border-left: none;
          background: white;
          color: black;
        }
      }

      a:first-child {
        border-left: 1px solid black;
      }
    }

  }
}