@import "variables";
@import '../../../utils/variables.scss';

.br-theme-fontawesome-stars-o {

  .br-widget {
    height: 28px;
    white-space: nowrap;
  
    a {
      font: normal normal normal 20px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      text-decoration: none;
      margin-right: 2px;
    }

    a:after {
      content: '\f006';
      color: $star-default;
    }

    a.br-active:after {
      content: '\f005';
      color: var(--theme-deafult);
    }

    a.br-selected:after {
      content: '\f005';
      color: var(--theme-deafult);
    }

    a.br-fractional:after {
      content: '\f123';
      color: var(--theme-deafult);
    }

    .br-current-rating {
      display: none;
    }
  }

  .br-readonly {
    a {
      cursor: default;
    }
  }

  .br-reverse {
    a.br-fractional {
      display: inline-block;
      transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }    
  }

}

@media print {
  .br-theme-fontawesome-stars-o {

    .br-widget {
      a:after {
        content: '\f006';
        color: black;
      }

      a.br-active:after,
      a.br-selected:after {
        content: '\f005';
        color: black;
      }

      a.br-fractional:after {
        content: '\f123';
        color: black;
      }
    }

  }
}
